import React, {useState} from 'react';
import './ToggleSwitch.css';
import styles from "../PopUp/PopUpImage.module.css";


const ToggleSwitch = ({checkBoxChecked, toggleCheckBox, sliderText}) => {

    return (<div className="toggle-switch-div">

        <label className='toggle-switch'>
            <input type="checkbox" checked={!checkBoxChecked} onChange={toggleCheckBox}/>
            <span className='toggle-switch-slider'></span>
        </label>
        <span className='toggle-switch-slider-text'>{sliderText}</span>

    </div>);

};

export default ToggleSwitch;