// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*
Scroll bar
 */

.scrollable-div {
    height: 200px;
    overflow-y: auto; /* Enable vertical scrollbar */
    border: 1px solid #ccc;
    padding: 10px;
}

/* Custom scrollbar */
.scrollable-div::-webkit-scrollbar {
    width: 12px;
}

.scrollable-div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Optional: Hide scrollbar when inactive (only works in WebKit based browsers) */
.scrollable-div::-webkit-scrollbar-thumb:active {
    background-color: #000;
}

/* Optional: Thin scrollbar for Safari and other browsers */
.scrollable-div {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/features/scroll-div.css"],"names":[],"mappings":";AACA;;EAEE;;AAEF;IACI,aAAa;IACb,gBAAgB,EAAE,8BAA8B;IAChD,sBAAsB;IACtB,aAAa;AACjB;;AAEA,qBAAqB;AACrB;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA,iFAAiF;AACjF;IACI,sBAAsB;AAC1B;;AAEA,2DAA2D;AAC3D;IACI,qBAAqB;IACrB,6BAA6B;AACjC","sourcesContent":["\n/*\nScroll bar\n */\n\n.scrollable-div {\n    height: 200px;\n    overflow-y: auto; /* Enable vertical scrollbar */\n    border: 1px solid #ccc;\n    padding: 10px;\n}\n\n/* Custom scrollbar */\n.scrollable-div::-webkit-scrollbar {\n    width: 12px;\n}\n\n.scrollable-div::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n\n.scrollable-div::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 6px;\n    border: 3px solid #f1f1f1;\n}\n\n.scrollable-div::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n}\n\n/* Optional: Hide scrollbar when inactive (only works in WebKit based browsers) */\n.scrollable-div::-webkit-scrollbar-thumb:active {\n    background-color: #000;\n}\n\n/* Optional: Thin scrollbar for Safari and other browsers */\n.scrollable-div {\n    scrollbar-width: thin;\n    scrollbar-color: #888 #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
