import React, {useState} from 'react';
import './CommentBox.css';
import {fetchCommentHighLite} from "../../services/benchmark";

const CommentBox = ({benchmarkID, startComment, startHighLight}) => {

    const [comment, setComment] = useState(startComment);
    const [highlightLevel, setHighlightLevel] = useState(startHighLight);
    const [isEditing, setIsEditing] = useState(false);

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleHighlightChange = (event) => {
        setHighlightLevel(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Here you can handle the submission logic
        console.log(`Comment: ${comment}, Highlight Level: ${highlightLevel}`);
        const response = await fetchCommentHighLite(benchmarkID, comment, parseInt(highlightLevel));

        if (response.success) {
            setIsEditing(false);
        } else {
            console.log(response);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };


    return (
        <form onSubmit={handleSubmit} className="comment-form">
            <div className="comment-inputs">
                <label htmlFor="comment">Comment:</label>
                <textarea
                    id="comment"
                    value={comment}
                    onChange={handleCommentChange}
                    rows={4}
                    cols={50}
                    required
                    disabled={!isEditing}
                    className="comment-textarea"
                />
                <div className="div-highlight-level">
                    <label htmlFor="highlightLevel">Highlight Level:</label>
                    <select
                        id="highlightLevel"
                        value={highlightLevel}
                        onChange={handleHighlightChange}
                        required
                        disabled={!isEditing}
                        className="highlight-level-select"
                    >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </div>
            </div>
            <div className="comment-buttons">
                <div>
                    <button type="button" onClick={handleEdit} className="edit-button" disabled={isEditing}>
                        Edit Comment
                    </button>

                </div>
                <div>
                    <button type="submit" disabled={!isEditing} className="submit-button">
                        Submit Comment
                    </button>
                    <button type="button" disabled={!isEditing} onClick={() => setIsEditing(false)} className="cancel-button">
                        Cancel Edit
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CommentBox;
