import React, {useState} from 'react';
import styles from './ImageSelector.module.css';
import TemporaryModal from "../../../features/Modals/TemporaryModal";
import Modal from "../../../features/Modals/Modal";


const ImageSelector = ({
                           possibleImages,
                           selectedImage,
                           setSelectedImage,
                           selectedImageProductId,
                           setSelectedImageProductId,
                           selectedLevel,
                           setSelectedLevel
                       }) => {


    // Function to handle click on an image in the multiple images div
    const handleImageClick = (image, imageProductId) => {
        setSelectedImage(image);
        setSelectedImageProductId(imageProductId);
    };

    // Function to handle level change
    const handleLevelChange = (level) => {
        setSelectedLevel(level);
        setSelectedImage('');
    };

    // Get images for the selected level
    const imagesForLevel = possibleImages[selectedLevel];

    return (<div className={styles.imageSelectorContainer}>
        <div className={styles.selectorContainer}>
            <div className={styles.levelToggleContainer}>
                <button onClick={() => handleLevelChange('all')}>All</button>
                <button onClick={() => handleLevelChange('shelf')}>Shelf</button>
                <button onClick={() => handleLevelChange('machine')}>Machine</button>
            </div>
            <div className={`${styles.multipleImagesContainer} ${styles.scrollableDiv}`}>
                {Array.isArray(imagesForLevel) && imagesForLevel.length > 0 && (imagesForLevel.map((image, index) => (<div key={index}
                                                                                                                           className={selectedImage.includes(image.imageUrl) ? `${styles.imageWithLabel} ${styles.imageWithLabelSelected}` : `${styles.imageWithLabel}`}
                                                                                                                           onClick={() => handleImageClick(image.imageUrl, image.id)}>
                    <div className={styles.divData}>
                        <span className={`${styles.imageLabel} ${styles.imageProductId}`}>{image.name === undefined ? image.id : image.name}</span>
                        <span className={`${styles.imageLabel} ${styles.imageShelf}`}>{image.shelf}</span>
                        <span className={`${styles.imageLabel} ${styles.imageShelf}`}>{image.row}</span>
                    </div>
                    <div className={styles.divImage}>
                        <img src={image.imageUrl} alt={image.id}/>
                    </div>
                </div>)))}

            </div>
        </div>
    </div>);
};

export default ImageSelector;
