// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    position: relative;
}

.modal-content {
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.validation-button,
.cancel-button {
    background-color: #0e732d; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 16px;
}

.cancel-button {
    background-color: #dc3545; /* Red color */
}

.validation-button:hover,
.cancel-button:hover {
    opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/features/Modals/Modal.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B,EAAE,gCAAgC;IAChE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,yCAAyC;IACzC,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;;IAEI,yBAAyB,EAAE,eAAe;IAC1C,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yBAAyB,EAAE,cAAc;AAC7C;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":["/* Modal.css */\n\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal {\n    background: white;\n    border-radius: 8px;\n    padding: 20px;\n    max-width: 500px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    z-index: 1001;\n    position: relative;\n}\n\n.modal-content {\n    margin-bottom: 20px;\n}\n\n.modal-buttons {\n    display: flex;\n    justify-content: space-between;\n    padding-top: 20px;\n}\n\n.validation-button,\n.cancel-button {\n    background-color: #0e732d; /* Blue color */\n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 10px 20px;\n    cursor: pointer;\n    margin: 0 10px;\n    font-size: 16px;\n}\n\n.cancel-button {\n    background-color: #dc3545; /* Red color */\n}\n\n.validation-button:hover,\n.cancel-button:hover {\n    opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
