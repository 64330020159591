// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ImageTableShow_imageSelectorContainer__a-m3K {
    margin-top: 20px;
    width: 100%;
}

.ImageTableShow_multipleImagesContainer__WTv0- {
    border: 1px solid #ccc;
    padding: 10px;
    gap: 25px;
    min-height: 650px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
}


.ImageTableShow_imageWithLabel__I3by5 {
    position: relative;
    display: inline-block;
    width: 216px;
    height: 150px;
    padding: 2px;


    border: dashed 1px;
    border-color: #bebebf;
}

.ImageTableShow_divImages__KFH4I {
    height: 70%;
    width: 100%;
    margin-top: 10px;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.ImageTableShow_divImages__KFH4I img {
    cursor: pointer;
    transition: border-color 0.3s ease;
    display: block;
    max-width: 40%;
    max-height: 100%;
    width: auto;
    height: auto;
}


.ImageTableShow_divImage__y7aMr img {
    max-width: 100%;
    height: auto;
    display: block;

}


.ImageTableShow_divButtons__O2n4\\+ {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
}

.ImageTableShow_divButtons__O2n4\\+ button {
    font-size: 6px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Demand/DemandData/ImageTableShow.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,iBAAiB;IACjB,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,UAAU;AACd;;;AAGA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,YAAY;;;IAGZ,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,kCAAkC;IAClC,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;;AAElB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\n.imageSelectorContainer {\n    margin-top: 20px;\n    width: 100%;\n}\n\n.multipleImagesContainer {\n    border: 1px solid #ccc;\n    padding: 10px;\n    gap: 25px;\n    min-height: 650px;\n    display: flex;\n    justify-content: flex-start;\n    align-content: flex-start;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 98%;\n}\n\n\n.imageWithLabel {\n    position: relative;\n    display: inline-block;\n    width: 216px;\n    height: 150px;\n    padding: 2px;\n\n\n    border: dashed 1px;\n    border-color: #bebebf;\n}\n\n.divImages {\n    height: 70%;\n    width: 100%;\n    margin-top: 10px;\n    align-content: center;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n.divImages img {\n    cursor: pointer;\n    transition: border-color 0.3s ease;\n    display: block;\n    max-width: 40%;\n    max-height: 100%;\n    width: auto;\n    height: auto;\n}\n\n\n.divImage img {\n    max-width: 100%;\n    height: auto;\n    display: block;\n\n}\n\n\n.divButtons {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    gap: 10px;\n}\n\n.divButtons button {\n    font-size: 6px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageSelectorContainer": `ImageTableShow_imageSelectorContainer__a-m3K`,
	"multipleImagesContainer": `ImageTableShow_multipleImagesContainer__WTv0-`,
	"imageWithLabel": `ImageTableShow_imageWithLabel__I3by5`,
	"divImages": `ImageTableShow_divImages__KFH4I`,
	"divImage": `ImageTableShow_divImage__y7aMr`,
	"divButtons": `ImageTableShow_divButtons__O2n4+`
};
export default ___CSS_LOADER_EXPORT___;
