import styles from "./ValidationBenchmarkPage.module.css";
import '../../../features/ToggleSwitch/ToggleSwitch.css'
import ImageSelector from "./ImageSelector";
import {redirect, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {fetchCrop, fetchImageResult, fetchShops} from "../../../services/benchmark";
import Modal from "../../../features/Modals/Modal";
import {toast} from "react-toastify";
import LoadingPopup from "../../../features/Loading/LodingPopup";
import PopUpImage from "../../../features/PopUp/PopUpImage";
import ToggleSwitch from "../../../features/ToggleSwitch/ToggleSwitch";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ValidationBenchmarkPage() {

    const [predictImage, setPredictImage] = useState('');
    const [predictProduct, setPredictProduct] = useState('');
    const [predictImageId, setPredictImageId] = useState('');
    const [possibleImages, setPossibleImages] = useState([]);

    const [firstImage, setFirstImage] = useState('');
    const [lastImage, setLastImage] = useState('');

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    // State to track selected image
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageProductId, setSelectedImageProductId] = useState('');
    // State to track the current level
    const [selectedLevel, setSelectedLevel] = useState('shelf');

    const [isNOK, setIsNOK] = useState(false);

    const toggleSwitch = () => {
        setIsNOK(!isNOK);
    };

    const hasRun = useRef(false);

    const {benchmarkID,} = useParams();
    const query = useQuery();
    const nullData = query.get('nullData'); // replace 'paramName' with your query parameter

    const navigate = useNavigate();
    const [navigateToBenchmark, setNavigateToBenchmark] = useState(false);

    // State to manage the checkbox input
    const [navigateChecked, setNavigateChecked] = useState(false);

    const [modalConfirmationOpen, setModalConfirmationOpen] = React.useState(false);

    const handleModalConfirmationCancel = () => {
        setModalConfirmationOpen(false);
        setNavigateToBenchmark(true)
    };
    const handleModalConfirmationValidation = () => {
        setModalConfirmationOpen(false);
        getCrop();
    };

    const handleModalConfirmationOpen = () => {
        setModalConfirmationOpen(true);
    };

    const handleCropSubmited = () => {
        //TODO: create a pop-up to show upload done!
        if (!navigateChecked) {
            getCrop();
        } else {
            setNavigateToBenchmark(true)
        }
    }

    const filterPossibleImages = (possibleImages, shelf) => {
        let dictPossibleImages = {
            'all': [], 'machine': possibleImages, 'shelf': possibleImages.filter(item => item.shelf === shelf)
        }

        return dictPossibleImages;
    }

    // Function to handle sending selected images to request
    const sendImageResult = async (type) => {

        let responseImageResult = await fetchImageResult(predictImageId, type, isNOK, selectedImageProductId);

        if (responseImageResult.success) {

            //handleModalConfirmationOpen();
            toast.success('Request was successful! ' + responseImageResult.result);
            handleCropSubmited();

        } else {
            toast.error("Request fail! " + responseImageResult.errorMessage);
        }
    };


    const getCrop = async () => {

        setPossibleImages([]);
        setSelectedImage('');
        setSelectedImageProductId('');
        setIsLoading(true);
        setIsNOK(false);
        setFirstImage('')
        setLastImage('')

        await toast.promise(
            fetchCrop(benchmarkID, nullData).then(responseImageResult => {
                if (responseImageResult.success) {
                    let responseData = responseImageResult.result

                    setPredictImage(responseData.url)
                    setFirstImage(responseData.firstUrl)
                    setLastImage(responseData.lastUrl)
                    setPossibleImages(filterPossibleImages(responseData.productsLayout, responseData.shelf))
                    setPredictImageId(responseData._id)

                    if (responseData.predictProduct) {
                        setPredictProduct(responseData.predictProduct)
                    } else {
                        setIsNOK(true)
                    }

                } else {
                    console.error('Request fail! ' + responseImageResult.errorMessage);
                    toast.error("Request fail! " + responseImageResult.errorMessage);
                    setNavigateToBenchmark(true)
                }
            }),
            {
                pending: 'Promise is pending (get crop)',
                success: 'Promise resolved 👌(get crop)',
                error: 'Promise rejected 🤯(get crop)'
            }
        );
    };


    const isButtonDisabled = () => {
        let disabled = true;
        if (!isNOK) {
            // Enable the item
            disabled = false;
        } else if (isNOK && selectedImage.length > 0) {
            // Enable the item
            disabled = false;
        } else {
            // Otherwise, disable the item
            disabled = true;
        }
        return disabled
    };


    const togglePopupVisibility = () => {
        setIsPopupVisible(!isPopupVisible);
    };


    useEffect(() => {
        console.log("Running use effect")
        if (!hasRun.current) {
            getCrop();

            // Set the ref to true so this block doesn't run again
            hasRun.current = true;
        }

    }, []);

    useEffect(() => {
        if (navigateToBenchmark) {
            navigate(`/benchmark/${benchmarkID}`);
        }
    }, [navigateToBenchmark]);


    return (<div className="main">
        <LoadingPopup isLoading={isLoading}/>

        <div className={'div_content'}>

            <div className={styles.divSplitWindow}>

                <div className={styles.singleImageContainer}>
                    {predictImage && (
                        <img src={predictImage} alt="predictImage"
                             onLoad={() => setIsLoading(false)}
                             onClick={togglePopupVisibility}/>
                    )}
                </div>

                <div className={styles.divMatch}>

                    <ToggleSwitch checkBoxChecked={!isNOK} toggleCheckBox={toggleSwitch} sliderText={isNOK ? 'NOK' : 'OK'}/>

                    {!isNOK ? (
                        <div className={styles.predictedProduct}>
                            <div className={styles.singleImageContainer}>
                                {predictProduct && (
                                    <img src={predictProduct.imageUrl} alt="predictProductImage"/>
                                )}
                            </div>
                            <label>Name: {predictProduct.name}</label> <br/>
                            <label>id: {predictProduct.id}</label> <br/>
                        </div>
                    ) : (
                        <ImageSelector
                            possibleImages={possibleImages}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                            selectedImageProductId={selectedImageProductId}
                            setSelectedImageProductId={setSelectedImageProductId}
                            selectedLevel={selectedLevel}
                            setSelectedLevel={setSelectedLevel}
                        />
                    )}

                </div>
            </div>

            <div className={styles.divContainer}>
                <div className={styles.checkboxContainer}>
                    <label>
                        <input
                            type="checkbox"
                            checked={navigateChecked}
                            onChange={(e) => setNavigateChecked(e.target.checked)}
                        />
                        Navigate to Benchmark
                    </label>
                </div>

                <div className={styles.buttonContainer}>
                    <button onClick={() => sendImageResult('discarded')}>
                        Discarded
                    </button>

                    <button onClick={() => sendImageResult('onHold')}>
                        onHold
                    </button>

                    <button onClick={() => sendImageResult('solved')}
                            disabled={isButtonDisabled()}>
                        {isNOK ? 'NOK' : 'OK'}
                    </button>

                </div>
            </div>
        </div>

        <PopUpImage firstImage={firstImage}
                    lastImage={lastImage}
                    isPopupVisible={isPopupVisible}
                    setIsPopupVisible={setIsPopupVisible}
                    setIsLoading={setIsLoading}/>

        <Modal
            isOpen={modalConfirmationOpen}
            onCancel={handleModalConfirmationCancel}
            onValidation={handleModalConfirmationValidation}
        >
            <>
                <h2>Upload successful!</h2>
                <h3>Get next crop?</h3>
            </>
        </Modal>


    </div>);
}