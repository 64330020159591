import React, {useState, useMemo, useEffect, useRef} from 'react';
import './TableDict.css';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

const TableDict = ({data, keys, baseEndPoint}) => {
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});
    const [searchQuery, setSearchQuery] = useState('');
    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoverPosition, setHoverPosition] = useState({top: 0, left: 0});


    const hasRun = useRef(false);

    const sortedData = useMemo(() => {
        let sortableData = [...data];

        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        if (searchQuery) {
            sortableData = sortableData.filter(row =>
                Object.values(row).some(
                    value => value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }

        return sortableData;
    }, [data, sortConfig, searchQuery]);


    const handleMouseEnter = (index, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setHoverPosition({top: rect.top + window.scrollY + 30, left: event.pageX - 400});
        setHoveredRow(index);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    useEffect(() => {

        if (!hasRun.current) {
            const key = 'createdAt';
            const direction = 'desc';
            setSortConfig({key, direction});

            // Set the ref to true so this block doesn't run again
            hasRun.current = true;
        }
    }, []);

    return (
        <>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
            />
            <table>
                <thead>
                <tr>
                    {keys.map((dict, i) => (
                        <th key={i} onClick={() => requestSort(dict['th'])}>
                            {dict['th']}
                            {sortConfig.key === dict['th'] ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : null}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortedData.length > 0 ? (
                    sortedData.map((row, index) => (
                        <tr key={index}
                            className={`table-row ${hoveredRow === index ? 'hover-highlight' : ''}`}
                            onMouseOver={(event) => handleMouseEnter(index, event)}
                            onMouseLeave={handleMouseLeave}>
                            {keys.map((dict, i) => (
                                <td key={i}>
                                    {((row['status'] !== 'In Progress' && row['status'] !== 'Failed') && dict['key'] === 'link') ?
                                        (
                                            <a href={`/${baseEndPoint}/${row['_id']}`} rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                            </a>
                                        ) : (
                                            row[dict['key']]
                                        )}
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={keys.length} style={{textAlign: 'center'}}>
                            No items to display
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {hoveredRow !== null && sortedData[hoveredRow]?.comment?.length > 0 && (
                <div className="comment-window" style={{top: hoverPosition.top, left: hoverPosition.left}}>
                    <textarea placeholder={sortedData[hoveredRow]['comment']} className="comment-field"></textarea>
                </div>
            )}
        </>
    );
};

export default TableDict;
