// Modal.js

import React from "react";
import ReactDOM from "react-dom";
import './Modal.css'

const modalRoot = document.getElementById("modal-root");

export default function Modal({isOpen, onCancel, onValidation, children}) {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">{children}</div>
                <div className={"modal-buttons"}>
                    <button className="validation-button" onClick={onValidation}>
                        YES
                    </button>
                    <button className="cancel-button" onClick={onCancel}>
                        NO
                    </button>
                </div>
            </div>
        </div>,
        modalRoot
    );
}
