// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-content {
    height: 90px;
    border-radius: 8px;
    background: #cdcdcd;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px;
 }


.header-nav {
    margin: 10px;
}

@media screen and (max-width: 767px) {

}`, "",{"version":3,"sources":["webpack://./src/components/style/Header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;CACf;;;AAGD;IACI,YAAY;AAChB;;AAEA;;AAEA","sourcesContent":[".header-content {\n    height: 90px;\n    border-radius: 8px;\n    background: #cdcdcd;\n    display: flex;\n    justify-content: left;\n    align-items: center;\n    margin: 10px;\n }\n\n\n.header-nav {\n    margin: 10px;\n}\n\n@media screen and (max-width: 767px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
