import React, {useEffect} from 'react';
import Modal from './Modal';

const TemporaryModal = ({message, isOpen, onClose}) => {

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000); // Disable after 5 seconds

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [isOpen, onClose]);

    return (
        <div>
            <div className='div-modal'>
                <div
                    onClick={onClose}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            background: "white",
                            height: 150,
                            width: 240,
                            margin: "auto",
                            padding: "2%",
                            border: "2px solid #000",
                            borderRadius: "10px",
                            boxShadow: "2px solid black",
                        }}
                    >
                        <>
                            <h1>GFG</h1>
                            <h3>{message}</h3> {/* Display the message passed by parameter */}
                            <button onClick={onClose}>Close</button>
                        </>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemporaryModal;
