import {makeRequestUtils} from "./fetchUtils";

const CLASSIFIER_URL = process.env.REACT_APP_CLASSIFIER_URL;

const CLASSIFIER_URL_API = `${CLASSIFIER_URL}`;

export const fetchOrganizations = async () => {
    console.log("Fetching organizations " + CLASSIFIER_URL_API + " " + CLASSIFIER_URL);
    return await makeRequestUtils(`${CLASSIFIER_URL_API}/organizations`, 'GET');
};

export const fetchMachines = async (organizationId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_API}/machines?organization=${organizationId}`, 'GET');
};
