import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {fetchCounterDemandData, fetchDemandData, fetchDemandDataToResolve, fetchDemands} from "../../../services/demand";
import ImageTableShow from "./ImageTableShow";

const BashInfo = () => {
    const [demandData, setDemandData] = useState([]);
    const [counterDemandData, setCounterDemandData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doubleClickSelectedImageToResolve, setDoubleClickSelectedImageToResolve] = useState(null);
    const [demandDataToResolve, setDemandDataToResolve] = useState([]);

    const hasRun = useRef(false);

    const {demandID} = useParams();
    const navigate = useNavigate();


    const getDemandData = async () => {

        setDemandData([]);
        setDemandDataToResolve([]);

        try {
            const [dataDemand,
                dataCounterDemand,
                dataToResolve
            ] = await Promise.all([
                fetchDemandData(demandID),
                fetchCounterDemandData(demandID),
                fetchDemandDataToResolve(demandID),
            ]);
            if (dataDemand.success) setDemandData(dataDemand.result);
            if (dataCounterDemand.success) setCounterDemandData(dataCounterDemand.result);

            if (dataToResolve.success) setDemandDataToResolve(dataToResolve.result);

            console.log("Set all data");
            return true;
        } catch (error) {
            console.error("One of the functions failed", error);
            return false;
        }

    };

    useEffect(() => {

        if (!hasRun.current) {
            console.log("Fetching data from server")

            getDemandData().then(() => {
                setLoading(false);
            });


            // Set the ref to true so this block doesn't run again
            hasRun.current = true;
        }


    }, []);


    useEffect(() => {
        console.log(doubleClickSelectedImageToResolve);
        if (doubleClickSelectedImageToResolve !== null) {
            navigate(`/validation/${demandID}?demandToResolve=${doubleClickSelectedImageToResolve}`);
        }
    }, [doubleClickSelectedImageToResolve]);

    const handleButtonClick = () => {
        navigate(`/validation/${demandID}`);
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!demandData || demandData.length === 0) {
        return <div>No data available</div>;
    }

    return (<div className="div-content">

            <div className="div-info">
                <h1>Demand Info</h1>

                <div>
                    <label>demand: {demandData._id}</label> <br/>
                </div>
                <div>
                    <label>organizationId: <span>{demandData.organizationId}</span></label> <br/>
                    <label>organizationAuthId: <span>{demandData.organizationAuthId}</span></label> <br/>
                    <label>machineCode: <span>{demandData.machineCode}</span></label> <br/>
                    <label>machineId: <span>{demandData.machineId}</span></label> <br/>

                </div>
                <div>
                    <label>status: <span>{demandData.status}</span></label> <br/>
                    <label>nrShops: <span>{demandData.nrShops}</span></label> <br/>
                    <label>startDate: <span>{demandData.startDate}</span></label> <br/>
                    <label>endDate: <span>{demandData.endDate}</span></label> <br/>
                </div>


            </div>

            <div className="div-info">
                <h1>Demand Counter</h1>

                <label>ToResolve: {counterDemandData.demandToResolve}</label> <br/>
                <label>InResolution: {counterDemandData.demandInResolution}</label> <br/>
                <label>Discarded: {counterDemandData.demandDiscarded}</label> <br/>
                <label>OnHold: {counterDemandData.demandOnHold}</label> <br/>
                <label>Resolved: {counterDemandData.demandResolved}</label> <br/>

                <button onClick={() => handleButtonClick()}
                        disabled={counterDemandData.demandToResolve === 0}>Validation data
                </button>

            </div>

            {counterDemandData.demandToResolve !== 0 && (<div className={'div-img-data'}>

                <div className={'div-result div-result-to-resolve'}>
                    <h1>ToResolve</h1>
                    <ImageTableShow images={demandDataToResolve} setDoubleClickImage={setDoubleClickSelectedImageToResolve}/>
                </div>


            </div>)}
        </div>

    );
};

export default BashInfo;
