// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    height: 60px;
    background-color: rgb(128, 128, 128);
    color: white;

    text-align: center;
    padding: 10px 0;
}

@media screen and (max-width: 767px) {

}`, "",{"version":3,"sources":["webpack://./src/components/style/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oCAAoC;IACpC,YAAY;;IAEZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;AAEA","sourcesContent":["footer {\n    height: 60px;\n    background-color: rgb(128, 128, 128);\n    color: white;\n\n    text-align: center;\n    padding: 10px 0;\n}\n\n@media screen and (max-width: 767px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
