// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValidationPageSelection_divContainer__OiiuH {
    margin-top: 20px;
}

.ValidationPageSelection_checkboxContainer__GX6h1 {
    font-size: 32px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.ValidationPageSelection_checkboxContainer__GX6h1 input {
    /* Adjust the width and height */
    width: 32px;
    height: 32px;
    /* Use transform to scale it up */
    transform: scale(1.5);
    /* Adjust the margin to align it properly */
    margin: 10px;
}

.ValidationPageSelection_buttonContainer__tQwQt {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 10px;
}


.ValidationPageSelection_buttonContainer__tQwQt button {

    width: 260px;
    height: 90px;
    padding: 10px 20px;
    font-size: 32px;

    border-radius: 60px;

    margin-top: 10px;
    align-self: flex-end;

}




`, "",{"version":3,"sources":["webpack://./src/pages/Demand/DemandValidation/ValidationPageSelection.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,qBAAqB;IACrB,2CAA2C;IAC3C,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,YAAY;AAChB;;;AAGA;;IAEI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;;IAEf,mBAAmB;;IAEnB,gBAAgB;IAChB,oBAAoB;;AAExB","sourcesContent":[".divContainer {\n    margin-top: 20px;\n}\n\n.checkboxContainer {\n    font-size: 32px;\n    cursor: pointer;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.checkboxContainer input {\n    /* Adjust the width and height */\n    width: 32px;\n    height: 32px;\n    /* Use transform to scale it up */\n    transform: scale(1.5);\n    /* Adjust the margin to align it properly */\n    margin: 10px;\n}\n\n.buttonContainer {\n    display: flex;\n    justify-content: flex-end;\n    gap: 20px;\n    margin: 10px;\n}\n\n\n.buttonContainer button {\n\n    width: 260px;\n    height: 90px;\n    padding: 10px 20px;\n    font-size: 32px;\n\n    border-radius: 60px;\n\n    margin-top: 10px;\n    align-self: flex-end;\n\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divContainer": `ValidationPageSelection_divContainer__OiiuH`,
	"checkboxContainer": `ValidationPageSelection_checkboxContainer__GX6h1`,
	"buttonContainer": `ValidationPageSelection_buttonContainer__tQwQt`
};
export default ___CSS_LOADER_EXPORT___;
