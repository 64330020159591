import {makeRequestUtils} from "./fetchUtils";

const CLASSIFIER_URL = process.env.REACT_APP_CLASSIFIER_URL;
const CLASSIFIER_URL_DEMAND = `${CLASSIFIER_URL}/demand`;

export const fetchDemands = async () => {
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}`, 'GET');
};

export const fetchDemandData = async (demandId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/${demandId}`, 'GET');
};

export const fetchCounterDemandData = async (demandId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/counter/${demandId}`, 'GET');
};



export const fetchDemandDataToResolve = async (demandId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/data/${demandId}/toResolve`, 'GET');
};

export const fetchShops = async (organizationId, organizationAuthId, machineId, machineCode, startDate, endDate) => {
    //const query = `organizationId=${organizationId}&organizationAuthId=${organizationAuthId}&machineId=${machineId}&machineCode=${machineCode}&startDate=${startDate}&endDate=${endDate}`
    const payload = {
        'organizationId': organizationId,
        'organizationAuthId': organizationAuthId,
        'machineId': machineId,
        'machineCode': machineCode,
        'startDate': startDate,
        'endDate': endDate
    }

    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/shops`, 'POST', {}, payload);
};




export const fetchCrop = async (demandId, demandToResolveId = null) => {
    let response = null;

    if (demandToResolveId) {
        response = await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/crop/${demandId}/toResolve/${demandToResolveId}`, 'GET');
    } else {
        response = await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/crop/${demandId}`, 'GET');
    }

    return response;
};

export const fetchImageResult = async (predictImageId, type, productId) => {
    /*
    discarded
    onHold
    solved ->
    const raw = JSON.stringify({
        "productId": selectedImageProductId
    });
     */
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/demandInResolution/${predictImageId}/${type}`, 'POST', {}, {'productId': productId});
};


export const fetchImageRemove = async (predictImageId, type, productId) => {
    /*
    discarded
    onHold
    solved ->
    const raw = JSON.stringify({
        "productId": selectedImageProductId
    });
     */
    return await makeRequestUtils(`${CLASSIFIER_URL_DEMAND}/demandToResolve/${predictImageId}/${type}`, 'POST', {}, {'productId': productId});
};

