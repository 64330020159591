import './App.css';
import {UserContextProvider} from "./UserContext";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import NotFound from "./pages/404";
import DemandsInfoPage from "./pages/Demand/DemandsInfo/DemandsInfoPage";
import ValidationPageSelection from "./pages/Demand/DemandValidation/ValidationPageSelection";
import BashInfo from "./pages/Demand/DemandData/DemandDataPage";
import BenchmarksInfoPage from "./pages/Benchmark/BenchmarksInfo/BenchmarksInfoPage";
import BenchmarkDataPage from "./pages/Benchmark/BenchmarkData/BenchmarkDataPage";
import ValidationBenchmarkPage from "./pages/Benchmark/BenchmarkValidation/ValidationBenchmarkPage";

function App() {
    return (
        < UserContextProvider>

            <Routes>
                <Route path="/" element={<Layout/>}>
                    {/*<Route path="/login" element={<LoginPage/>}/>*/}
                    {/*<Route path="/register" element={<RegisterPage/>}/>*/}
                    {/*<Route path="/confirm/:id" element={<ConfirmPage/>}/>*/}

                    <Route path="/processedSessions" element={<DemandsInfoPage/>}/>
                    <Route path="/processedBenchmark" element={<BenchmarksInfoPage/>}/>

                    <Route path="/bash/:demandID" element={<BashInfo/>}/>
                    <Route path="/benchmark/:benchmarkID" element={<BenchmarkDataPage/>}/>

                    <Route path="/validation/:demandID" element={<ValidationPageSelection/>}/>
                    <Route path="/validationBenchmark/:benchmarkID" element={<ValidationBenchmarkPage/>}/>

                    <Route path='*' element={<NotFound/>}/>
                </Route>
            </Routes>
        </UserContextProvider>

    );
}

export default App;
