import {useState} from "react";
import './style/NavBar.css'
import {Link, Navigate} from "react-router-dom";

//import logo from '../logo.svg'
import logo from '../rk-icon.png'


const Navbar = () => {

    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Navigate to={'/'}/>
    }


    return (
        <div className={'nav-bar'}>
            <img src={logo} className="App-logo" alt="logo"/>
            {/*<Link to="/" className="logo">Reckon.ai</Link>*/}
            <div className="navbar-element">
                <Link className="navbar-element-link"
                      to="/processedSessions">Sessions</Link>
                <Link className="navbar-element-link"
                      to="/processedBenchmark">Benchmarks</Link>
            </div>

        </div>
    );
};

export default Navbar;
