// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Make the body take full height */
main {
    max-width: 1300px;
    height: 100%;
    display: flex;
    margin: auto;
    align-content: center;
    flex-direction: column;
}

.outlet-content {
    max-width: 1280px;
    min-height: 870px;
    padding: 10px;
}


.div_content {
    padding: 10px 10px 30px;
}


@media screen and (max-width: 767px) {
    .outlet-content {
        width: 90%;
    }
}




`, "",{"version":3,"sources":["webpack://./src/components/style/Layout.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;;;AAGA;IACI,uBAAuB;AAC3B;;;AAGA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":["/* Make the body take full height */\nmain {\n    max-width: 1300px;\n    height: 100%;\n    display: flex;\n    margin: auto;\n    align-content: center;\n    flex-direction: column;\n}\n\n.outlet-content {\n    max-width: 1280px;\n    min-height: 870px;\n    padding: 10px;\n}\n\n\n.div_content {\n    padding: 10px 10px 30px;\n}\n\n\n@media screen and (max-width: 767px) {\n    .outlet-content {\n        width: 90%;\n    }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
