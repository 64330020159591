import React, {useState} from 'react';
import styles from './ImageTableShow.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faPauseCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {fetchImageRemove} from "../../../services/demand";
import {toast} from "react-toastify";

const ImageTableShow = ({images, setDoubleClickImage}) => {

    const [hover, setHover] = useState(false);


    const sendImageResult = async (predictImageId, type) => {

        let responseImageResult = await fetchImageRemove(predictImageId, type);

        if (responseImageResult.success) {
            //handleModalConfirmationOpen();
            toast.success('Request was successful! ' + responseImageResult.result);
        } else {
            toast.error("Request fail! " + responseImageResult.errorMessage);
        }
    };

    return (<div>
            <div className={styles.imageSelectorContainer}>
                <div className={`${styles.multipleImagesContainer} scrollable-div`}>
                    {Array.isArray(images) && images.length > 0 && (images.map((image, index) => (<div key={index}
                                                                                                       className={styles.imageWithLabel}>

                        <div className={styles.divButtons}>
                            <button onClick={() => sendImageResult(image._id, 'discarded')}><FontAwesomeIcon icon={faTrash}/>Discard</button>
                            <button onClick={() => sendImageResult(image._id, 'onHold')}><FontAwesomeIcon icon={faPauseCircle}/>On Hold</button>
                            <button onClick={() => setDoubleClickImage(image._id)}><FontAwesomeIcon icon={faSpinner}/></button>
                        </div>
                        <div className={styles.divImages}>
                            <img src={image.url}
                                 alt={image._id}
                                 onDoubleClick={() => setDoubleClickImage(image._id)}/>
                        </div>
                    </div>)))}
                </div>
            </div>
        </div>
    );
};

export default ImageTableShow;
