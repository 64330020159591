import styles from "./DemandsInfoPage.module.css";
import TableDict from "../../../features/TableDict";
import {useEffect, useRef, useState} from "react";
import {fetchMachines, fetchOrganizations} from "../../../services/api";

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {fetchDemands, fetchShops} from "../../../services/demand";

export default function DemandsInfoPage() {


    //TODO: fetch this data to server
    const [demandData, setDemandData] = useState([])

    const [tableKeys, setTableKeys] = useState([{'key': 'organizationAuthId', 'th': 'organization'}, {
        'key': 'machineCode', 'th': 'machine'
    }, {'key': 'startDate', 'th': 'start'}, {'key': 'endDate', 'th': 'end'}, {'key': 'nrShops', 'th': 'nrShops'}, {
        'key': 'createdAt', 'th': 'createdAt'
    }, {'key': 'status', 'th': 'status'}, {
        'key': 'link', 'th': ''
    }])


    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [organizationOption, setOrganizationOption] = useState([]);

    const [selectedMachine, setSelectedMachine] = useState('');
    const [machineOption, setMachineOption] = useState([]);


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enableSubmit, setEnableSubmit] = useState(false);

    // Combine inputs into a variable to use as a dependency
    const combinedInputs = `${selectedOrganization}-${selectedMachine}-${startDate}-${endDate}`;

    const hasRun = useRef(false);

    const parseDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day);
    };

    const getOrganizations = async () => {
        setOrganizationOption([])
        setSelectedOrganization('')

        let dataOrganization = await toast.promise(fetchOrganizations(), {
            pending: 'Promise is pending (get organizations)',
            success: 'Promise resolved 👌(get organizations)',
            error: 'Promise rejected 🤯(get organizations)'
        });

        if (dataOrganization.success) {
            let data = dataOrganization.result;

            const dataOption = data.map(item => ({
                value: item.authId, label: item.authId, _id: item._id, metadata: item
            }));

            setOrganizationOption(dataOption);
            if (dataOption.length > 0) {
                setSelectedOrganization(dataOption[0]);
            }
        }
    };

    const getDemands = async () => {

        setDemandData([])
        let dataDemand = await toast.promise(fetchDemands(), {
            pending: 'Promise is pending (get demands)', success: 'Promise resolved 👌(get demands)', error: 'Promise rejected 🤯(get demands)'
        });

        console.log(demandData)
        if (dataDemand.success) {
            let data = dataDemand.result;
            setDemandData(data);
        }

    };


    const getMachines = async (organizationId) => {

        setMachineOption([])
        setSelectedMachine('')

        let dataMachines = await toast.promise(fetchMachines(organizationId), {
            pending: 'Promise is pending (get machines)', success: 'Promise resolved 👌(get machines)', error: 'Promise rejected 🤯(get machines)'
        });


        if (dataMachines.success) {
            let data = dataMachines.result;
            console.log(data)

            const dataOption = data.map(item => ({
                value: item.code, label: item.code, _id: item._id, metadata: item
            }));
            setMachineOption(dataOption);
            if (dataOption.length > 0) {
                setSelectedMachine(dataOption[0]);
            }
        }

    };


    useEffect(() => {

        if (!hasRun.current) {
            console.log("Getting data from server")

            getDemands();
            getOrganizations();

            setLoading(false);

            // Set the ref to true so this block doesn't run again
            hasRun.current = true;
        }

    }, []);

    useEffect(() => {
        //TODO: force to update vars
        if (selectedOrganization !== null && selectedOrganization !== '' && selectedMachine !== null && selectedMachine !== '' && startDate !== null && startDate !== '' && endDate !== null && endDate !== '') {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }

    }, [combinedInputs]);


    if (loading) {
        return <div>Loading...</div>;
    }

    // if (error) {
    //     return <div>Error: {error.message}</div>;
    // }

    const handleOrganizationChange = (e) => {
        const newValue = e.target.value;

        let organizationData = organizationOption.find(item => item.value === newValue);
        console.log(organizationData)
        setSelectedOrganization(organizationData);

        getMachines(organizationData._id);
    };

    const handleMachineChange = (e) => {
        const newValue = e.target.value;
        //get _id of machine
        console.log(newValue)

        let machineData = machineOption.find(item => item.value === newValue);
        console.log(machineData)
        setSelectedMachine(machineData);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value)
    };

    const handleEndDataChange = (e) => {
        setEndDate(e.target.value)
    };


    const handleSubmit = async () => {
        let responseShops = await fetchShops(selectedOrganization.metadata._id, selectedOrganization.metadata.authId, selectedMachine.metadata._id, selectedMachine.metadata.code, startDate, endDate);

        if (responseShops.success) {

            toast.success("Request was successful!");
            await getDemands()

        } else {
            toast.error("Request fail!");
        }
    };

    return (

        <div className="main">
            <p>Lekkerland 0001 - 646fe0be3986f4001613904d</p>
            <p>Carrefour 0001 - 6512f9547b54dc0016a6c4d8</p>
            <p>Elior 0004 - 662298e851b4d90013281b61</p>

            <div className={styles.divStartProcessSessionDivContent}>
                <h1>Start Process Session</h1>
                <div className={`${styles.divInputCustomSelect} ${styles.divInput}`}>
                    <label>Organization:</label>
                    <select
                        value={selectedOrganization.value}
                        onChange={handleOrganizationChange}
                        disabled={organizationOption.length === 0}
                    >
                        {organizationOption.map((option) => (<option key={option.value} value={option.value}>
                            {option.label}
                        </option>))}
                    </select>


                </div>

                <div className={`${styles.divInputCustomSelect} ${styles.divInput}`}>
                    <label>Machine:</label>
                    <select
                        value={selectedMachine.value}
                        onChange={handleMachineChange}
                        disabled={machineOption.length === 0}
                    >
                        {machineOption.map((option) => (<option key={option.value} value={option.value}>
                            {option.label}
                        </option>))}
                    </select>
                </div>

                <div className={styles.divInput}>
                    <label>Start Date:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        className={styles.inputField}
                    />
                </div>
                <div className={styles.divInput}>
                    <label>End Date:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDataChange}
                        className={styles.inputField}
                    />
                </div>

                <div className={styles.divButton}>
                    <button onClick={handleSubmit}
                            disabled={enableSubmit == false}>
                        START
                    </button>
                </div>
            </div>

            <div className={styles.divProcessedSessionsDivContent}>
                <h1>Processed Sessions</h1>
                <TableDict data={demandData} keys={tableKeys} baseEndPoint='bash'/>
            </div>


        </div>);
}