// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.HoverInfoBox_hoverInfoBox__m2jCt {
    background-color: rgba(128, 128, 128, 0.9); /* Gray background with some transparency */
    color: white;
    padding: 10px;
    border: 1px solid #ccc;
    z-index: 1000; /* Ensure it appears in front */
    display: inline-block;
    position: absolute; /* Allow positioning */
    width: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/features/HoverInfoBox/HoverInfoBox.module.css"],"names":[],"mappings":";AACA;IACI,0CAA0C,EAAE,2CAA2C;IACvF,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,aAAa,EAAE,+BAA+B;IAC9C,qBAAqB;IACrB,kBAAkB,EAAE,sBAAsB;IAC1C,aAAa;AACjB","sourcesContent":["\n.hoverInfoBox {\n    background-color: rgba(128, 128, 128, 0.9); /* Gray background with some transparency */\n    color: white;\n    padding: 10px;\n    border: 1px solid #ccc;\n    z-index: 1000; /* Ensure it appears in front */\n    display: inline-block;\n    position: absolute; /* Allow positioning */\n    width: 1000px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverInfoBox": `HoverInfoBox_hoverInfoBox__m2jCt`
};
export default ___CSS_LOADER_EXPORT___;
