import React, {useState} from 'react';
import styles from './PopUpImage.module.css';
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";


const PopUpImage = ({
                        firstImage, lastImage, isPopupVisible, setIsPopupVisible, setIsLoading
                    }) => {

    const [showFirstImage, setShowFirstImage] = useState(true);
    const [sliderText, setSliderText] = useState('First');


    // Function to toggle between first and last image
    const toggleFirstLastImage = () => {
        setShowFirstImage(!showFirstImage);
        setSliderText(showFirstImage ? 'Last' : 'First');
    };

    // Function to close the popup
    const closePopup = () => {
        setIsPopupVisible(false);
    };

    return (<div className="content">
        {isPopupVisible && (<div className={styles.popup}>
            <div className={styles.popupContent}>
                <div className={styles.divTopBar}>
                    <div className={styles.popupContentLeft}>
                        <ToggleSwitch checkBoxChecked={!showFirstImage} toggleCheckBox={toggleFirstLastImage} sliderText={sliderText}/>
                    </div>
                    <button className={styles.closeButton} onClick={closePopup}>
                        Close
                    </button>
                </div>

                <div className={styles.popupContentDivImage}>
                    {showFirstImage && firstImage && (<img src={firstImage} alt="firstImage"
                                                           onLoad={() => setIsLoading(false)}/>)}
                    {!showFirstImage && lastImage && (<img src={lastImage} alt="lastImage"
                                                           onLoad={() => setIsLoading(false)}/>)}
                </div>
            </div>

        </div>)}
    </div>);

};

export default PopUpImage;