// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BenchmarksInfoPage_divStartProcessBenchmarkDivContent__41Qf9 {
    border: 1px solid #ccc;
    padding: 10px
}


.BenchmarksInfoPage_divInputCustomSelect__3Vm2V {

}

.BenchmarksInfoPage_divInput__DnFlj {
    display: flex;
    align-items: center; /* Aligns input and label vertically centered */
    justify-content: space-between;
    width: 350px;
    position: relative;
    margin: 8px;
}


.BenchmarksInfoPage_inputField__p8GGT {
    width: 182px;
}

.BenchmarksInfoPage_divButton__Yo2Vg {
margin: 10px
}


.BenchmarksInfoPage_divInput__DnFlj select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    width: 200px;
}


.BenchmarksInfoPage_divInput__DnFlj input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    width: 180px;
}


.BenchmarksInfoPage_divProcessedBenchmarkDivContent__rSu27 {

}`, "",{"version":3,"sources":["webpack://./src/pages/Benchmark/BenchmarksInfo/BenchmarksInfoPage.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB;AACJ;;;AAGA;;AAEA;;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,+CAA+C;IACpE,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;;AAGA;IACI,YAAY;AAChB;;AAEA;AACA;AACA;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;;AAGA;;AAEA","sourcesContent":[".divStartProcessBenchmarkDivContent {\n    border: 1px solid #ccc;\n    padding: 10px\n}\n\n\n.divInputCustomSelect {\n\n}\n\n.divInput {\n    display: flex;\n    align-items: center; /* Aligns input and label vertically centered */\n    justify-content: space-between;\n    width: 350px;\n    position: relative;\n    margin: 8px;\n}\n\n\n.inputField {\n    width: 182px;\n}\n\n.divButton {\nmargin: 10px\n}\n\n\n.divInput select {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 14px;\n    color: #333;\n    width: 200px;\n}\n\n\n.divInput input {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 14px;\n    color: #333;\n    width: 180px;\n}\n\n\n.divProcessedBenchmarkDivContent {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divStartProcessBenchmarkDivContent": `BenchmarksInfoPage_divStartProcessBenchmarkDivContent__41Qf9`,
	"divInputCustomSelect": `BenchmarksInfoPage_divInputCustomSelect__3Vm2V`,
	"divInput": `BenchmarksInfoPage_divInput__DnFlj`,
	"inputField": `BenchmarksInfoPage_inputField__p8GGT`,
	"divButton": `BenchmarksInfoPage_divButton__Yo2Vg`,
	"divProcessedBenchmarkDivContent": `BenchmarksInfoPage_divProcessedBenchmarkDivContent__rSu27`
};
export default ___CSS_LOADER_EXPORT___;
