import styles from './ValidationPageSelection.module.css';

import ImageSelector from "./ImageSelector";
import {redirect, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import imageSelector from "./ImageSelector";
import {fetchCrop, fetchImageResult, fetchShops} from "../../../services/demand";
import Modal from "../../../features/Modals/Modal";
import {toast} from "react-toastify";
import LoadingPopup from "../../../features/Loading/LodingPopup";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ValidationPageSelection() {

    //TODO: if get demandToResolve get data from endpoint /demand/crop/toResolve/:demandToResolve


    const [predictImage, setPredictImage] = useState('');

    const [firstImage, setFirstImage] = useState('');
    const [lastImage, setLastImage] = useState('');
    const [predictImageId, setPredictImageId] = useState('');
    const [possibleImages, setPossibleImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    // State to track selected image
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageProductId, setSelectedImageProductId] = useState('');
    // State to track the current level
    const [selectedLevel, setSelectedLevel] = useState('shelf');


    const hasRun = useRef(false);

    const {demandID} = useParams();
    const query = useQuery();
    const demandToResolve = query.get('demandToResolve');


    const navigate = useNavigate();
    const [navigateToBash, setNavigateToBash] = useState(false);

    // State to manage the checkbox input
    const [navigateChecked, setNavigateChecked] = useState(false);

    const [modalConfirmationOpen, setModalConfirmationOpen] = React.useState(false);

    const handleModalConfirmationCancel = () => {
        setModalConfirmationOpen(false);
        setNavigateToBash(true)
    };
    const handleModalConfirmationValidation = () => {
        setModalConfirmationOpen(false);
        getCrop();
    };

    const handleModalConfirmationOpen = () => {
        setModalConfirmationOpen(true);
    };

    const handleCropSubmited = () => {
        //TODO: create a pop-up to show upload done!
        if (!navigateChecked) {
            getCrop();
        } else {
            setNavigateToBash(true)
        }
    }

    const filterPossibleImages = (possibleImages, shelf) => {
        let dictPossibleImages = {
            'all': [], 'machine': possibleImages, 'shelf': possibleImages.filter(item => item.shelf === shelf)
        }

        return dictPossibleImages;
    }

    // Function to handle sending selected images to request
    const sendImageResult = async (type) => {

        let responseImageResult = await fetchImageResult(predictImageId, type, selectedImageProductId);

        if (responseImageResult.success) {

            //handleModalConfirmationOpen();
            toast.success('Request was successful! ' + responseImageResult.result);
            handleCropSubmited();

        } else {
            toast.error("Request fail! " + responseImageResult.errorMessage);
        }
    };

    const getCrop = async (demandToResolveId = null) => {
        setIsLoading(true);
        setPossibleImages([])
        setSelectedImage('')
        setFirstImage('')
        setLastImage('')
        setSelectedImageProductId('')

        await toast.promise(fetchCrop(demandID, demandToResolveId).then(responseImageResult => {
            if (responseImageResult.success) {
                let responseData = responseImageResult.result

                setPredictImage(responseData.url)
                setFirstImage(responseData.firstUrl)
                setLastImage(responseData.lastUrl)
                setPossibleImages(filterPossibleImages(responseData.products, responseData.shelf))
                setPredictImageId(responseData._id)

            } else {
                console.error('Request fail! ' + responseImageResult.errorMessage);
                toast.error("Request fail! " + responseImageResult.errorMessage);
                setNavigateToBash(true)
            }
        }), {
            pending: 'Promise is pending (get crop)', success: 'Promise resolved 👌(get crop)', error: 'Promise rejected 🤯(get crop)'
        });
    };


    useEffect(() => {
        console.log("Running use effect")
        if (!hasRun.current) {
            if (demandToResolve) {
                setNavigateChecked(true);
            }
            getCrop(demandToResolve);

            // Set the ref to true so this block doesn't run again
            hasRun.current = true;
        }

    }, []);

    useEffect(() => {
        if (navigateToBash) {
            navigate(`/bash/${demandID}`);
        }
    }, [navigateToBash]);


    return (<div className="main">
        <LoadingPopup isLoading={isLoading}/>
        <div className={"div_content"}>
            <ImageSelector predictImageId={predictImageId} predictImage={predictImage}
                           firstImage={firstImage}
                           lastImage={lastImage}
                           possibleImages={possibleImages}
                           selectedImage={selectedImage}
                           setSelectedImage={setSelectedImage}
                           selectedImageProductId={selectedImageProductId}
                           setSelectedImageProductId={setSelectedImageProductId}
                           selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel}
                           setIsLoading={setIsLoading}/>


            <div className={styles.divContainer}>
                <div className={styles.checkboxContainer}>
                    <label>
                        <input
                            type="checkbox"
                            checked={navigateChecked}
                            onChange={(e) => setNavigateChecked(e.target.checked)}
                        />
                        Navigate Back
                    </label>
                </div>

                <div className={styles.buttonContainer}>
                    <button onClick={() => sendImageResult('discarded')}>
                        Discard
                    </button>

                    <button onClick={() => sendImageResult('onHold')}>
                        onHold
                    </button>

                    <button onClick={() => sendImageResult('solved')}
                            disabled={selectedImage.length === 0}>
                        Classify
                    </button>

                </div>
            </div>
        </div>

        <Modal
            isOpen={modalConfirmationOpen}
            onCancel={handleModalConfirmationCancel}
            onValidation={handleModalConfirmationValidation}
        >
            <>
                <h2>Upload successful!</h2>
                <h3>Get next crop?</h3>
            </>
        </Modal>


    </div>);
}