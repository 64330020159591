import React, {useState} from 'react';
import styles from './ImageTableShow.module.css'
import HoverInfoBox from "../../../features/HoverInfoBox/HoverInfoBox";


const ImageTableShow = ({title, images}) => {

    const [hover, setHover] = useState(false);
    const [hoverImageData, setHoverImageData] = useState('');
    const [predictedItem, setPredictedItem] = useState('');
    const [validationItem, setValidationItem] = useState('');
    const [divVisible, setDivVisible] = useState(false);


    const [position, setPosition] = useState({x: 0, y: 0});


    // Toggle functions
    const toggleDiv = () => setDivVisible(!divVisible);


    const handleMouseMove = (event) => {
        setPosition({
            x: event.pageX + 10, // Adjust the x-coordinate
            y: event.pageY + 10, // Adjust the y-coordinate
        });
    };

    const handleMouseEnter = (image) => {
        setHoverImageData(image);
        setHover(true);

        if (image.predictProduct) setPredictedItem(image.productsClassifier.find(item => item.id === image.predictProduct.id));

        if (image.validationProductId) setValidationItem(image.productsClassifier.find(item => item.id === image.validationProductId));
        else setValidationItem(image.productsClassifier.find(item => item.id === image.metadata.max_score_predict));
    };

    const handleMouseLeave = () => {
        setHoverImageData('')
        setHover(false);

        setValidationItem('')
        setPredictedItem('')
    };

    return (
        <div>
            <div className={styles.titleData}>
                <h1>{title}</h1>
                <span>Length = {images.length}</span>
                <button onClick={toggleDiv} disabled={images.length === 0}>
                    {divVisible ? 'Minimize Div' : 'Expand Div'}
                </button>
            </div>

            {divVisible &&
                <div className={styles.imageSelectorContainer}>
                    <div className={`${styles.multipleImagesContainer} scrollable-div`}>
                        {Array.isArray(images) && images.length > 0 && (
                            images.map((image, index) => (
                                <div key={index}
                                     className={styles.imageWithLabel}>
                                    {image.predictProduct &&
                                        <span className={`${styles.imageLabel} ${styles.imageProductName}`}>{image.predictProduct.name}</span>}
                                    {image.predictProduct &&
                                        <span className={`${styles.imageLabel} ${styles.imageShelf}`}>{image.predictProduct.id}</span>}
                                    {/*{image.shelf && <span className="image-label image-shelf">{image.shelf}</span>}*/}
                                    <div className={styles.divImages}>
                                        <img src={image.url}
                                             alt={image._id}
                                             onMouseEnter={() => handleMouseEnter(image)}
                                             onMouseMove={handleMouseMove}
                                             onMouseLeave={handleMouseLeave}/>

                                            {image.predictProduct && <img src={image.predictProduct.imageUrl} alt={image.id}/>}
                                            {image.validationProduct && <img src={image.validationProduct.imageUrl} alt={image.id}/>}

                                    </div>
                                </div>
                            ))
                        )}

                    </div>
                </div>
            }
            {
                hover && hoverImageData && (
                    <HoverInfoBox position={position}
                                  hoverImageData={hoverImageData}
                                  predictedItem={predictedItem}
                                  validationItem={validationItem}/>
                )}

        </div>

    );
};

export default ImageTableShow;
