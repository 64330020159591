import Header from "./Header";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";
import './style/Layout.css'
import './style/Default.css'
import './../features/scroll-div.css'
import '../features/ToggleSwitch/ToggleSwitch.css'
import {ToastContainer} from "react-toastify";

export default function Layout() {


    return (
        <main>
            <Header/>
            <div className="outlet-content">
                <Outlet/>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
            <Footer/>
        </main>
    );
}