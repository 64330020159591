// fetchUtils.js
import axios from 'axios';


export const successResult = function (success, result) {
    return {
        appCode: 0,
        success,
        errorMessage: success ? null : typeof result === "object" ? result : {message: result},
        result: success ? result : null,
        date: new Date().getTime(),
    };
};
export const makeRequestUtils = async function (url, method, headers = {}, payload = null, TIMEOUT = 2000) {

    const options = {
        method,
        url,
        headers: {'Content-Type': 'application/json', ...headers},
        timeout: TIMEOUT,
    };

    if (payload) {
        options.data = payload;
    }

    try {
        const response = await axios(options);

        console.log(response.data);

        if (!response.data.success) {
            throw new Error('Network response was not success');
        }

        return successResult(true, response.data.result);
    } catch (ex) {
        console.error(ex);
        console.error("utils/makeRequestUtils: " + JSON.stringify({url, method, headers, payload, exception: ex, error: '0000'}));
        return successResult(false, '0000');
    }
};
