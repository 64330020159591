// src/components/LoadingPopup.js
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Popup = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const LoadingPopup = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <Overlay>
      <Popup>
        <p>Loading...</p>
      </Popup>
    </Overlay>
  );
};

export default LoadingPopup;
