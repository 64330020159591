import React, {useState} from 'react';
import styles from './HoverInfoBox.module.css';


const HoverInfoBox = ({
                        position, hoverImageData, predictedItem, validationItem
                    }) => {


    return (<div className={styles.hoverInfoBox}
                 style={{top: `${position.y}px`}}>
        <h3>{hoverImageData._id}</h3>
        {hoverImageData.predictProduct && (<>
            <p>Product Name: {hoverImageData.predictProduct.name}</p>
            <p>Product ID: {hoverImageData.predictProduct.id}</p>
        </>)}
        <p>validation productID: {hoverImageData.validationProductId}</p>
        {hoverImageData.shelf && <p>Shelf: {hoverImageData.shelf}</p>}

        {predictedItem && (<div>
            <h3>Predicted :</h3>
            <label>id: <span>{predictedItem.id}</span></label> <br/>
            <label>Name: <span>{predictedItem.name}</span></label> <br/>
            <label>count: <span>{predictedItem.count}</span></label> <br/>
            <img src={predictedItem.imageUrl}
                 style={{maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto', borderRadius: '5px'}}/>

        </div>)}

        {validationItem && (<div>
            <h3>Validation :</h3>
            <label>id: <span>{validationItem.id}</span></label> <br/>
            <label>Name: <span>{validationItem.name}</span></label> <br/>
            <label>count: <span>{validationItem.count}</span></label> <br/>
            <img src={validationItem.imageUrl}
                 style={{maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto', borderRadius: '5px'}}/>

        </div>)}

        {hoverImageData.metadata && (<div>
            <h3>Metadata:</h3>
            <ul>
                {Object.entries(hoverImageData.metadata).map(([key, value]) => (<li key={key}>
                    <strong>{key}:</strong> {String(value)}
                </li>))}
            </ul>
        </div>)}

    </div>);

};

export default HoverInfoBox;