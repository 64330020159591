import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    fetchBenchmarkData,
    fetchBenchmarkMetrics,
    fetchBenchmarkDataResolvedPredictedOK,
    fetchBenchmarkDataResolvedPredictedNOKTrained,
    fetchBenchmarkDataResolvedPredictedNOKNotTrained,
    fetchBenchmarkDataResolvedNotPredictedNOKNotTrained,
    fetchBenchmarkDataResolvedNotPredictedOK,
    fetchBenchmarkDataResolvedNotPredictedNOKTrained,
    fetchBenchmarkDataOnHoldPredicted,
    fetchBenchmarkDataOnHoldNotPredicted,
    fetchBenchmarkDataDiscardedPredicted,
    fetchBenchmarkDataDiscardedNotPredicted,
    fetchBenchmarkDataToResolvePredicted,
    fetchBenchmarkDataToResolveNotPredicted,
    fetchBenchmarkCounter,
    fetchCommentHighLite
} from "../../../services/benchmark";
import './BenchmarkDataPage.module.css'
import ImageSelector from "../BenchmarkValidation/ImageSelector";
import ImageTableShow from "./ImageTableShow";
import CommentBox from "../../../features/CommentBox/CommentBox";

const BenchmarkDataPage = () => {
    const [benchmarkData, setBenchmarkData] = useState([]);
    const [counterBenchmarkData, setCounterBenchmarkData] = useState([]);
    const [benchmarkMetrics, setBenchmarkMetrics] = useState([]);
    const [benchmarkMetricsData, setBenchmarkMetricsData] = useState([]);

    const [benchmarkMetricsDataResolvedPredictedOK, setBenchmarkMetricsDataResolvedPredictedOK] = useState([]);
    const [benchmarkMetricsDataResolvedPredictedNKTrained, setBenchmarkMetricsDataResolvedPredictedNKTrained] = useState([]);
    const [benchmarkMetricsDataResolvedPredictedNKNotTrained, setBenchmarkMetricsDataResolvedPredictedNKNotTrained] = useState([]);

    const [benchmarkMetricsDataResolvedNotPredictedOK, setBenchmarkMetricsDataResolvedNotPredictedOK] = useState([]);
    const [benchmarkMetricsDataResolvedNotPredictedNKTrained, setBenchmarkMetricsDataResolvedNotPredictedNKTrained] = useState([]);
    const [benchmarkMetricsDataResolvedNotPredictedNKNotTrained, setBenchmarkMetricsDataResolvedNotPredictedNKNotTrained] = useState([]);

    const [benchmarkMetricsDataOnHoldPredicted, setBenchmarkMetricsDataOnHoldPredicted] = useState([]);
    const [benchmarkMetricsDataOnHoldNotPredicted, setBenchmarkMetricsDataOnHoldNotPredicted] = useState([]);
    const [benchmarkMetricsDataDiscardedPredicted, setBenchmarkMetricsDataDiscardedPredicted] = useState([]);
    const [benchmarkMetricsDataDiscardedNotPredicted, setBenchmarkMetricsDataDiscardedNotPredicted] = useState([]);

    const [benchmarkDiscardedData, setBenchmarkDiscardedData] = useState([]);
    const [benchmarkOnHoldData, setBenchmarkOnHoldData] = useState([]);

    const [loading, setLoading] = useState(true);

    const hasRun = useRef(false);

    const {benchmarkID} = useParams();
    const navigate = useNavigate();

    const [startComment, setStartComment] = useState('');
    const [startHighLight, setStartHighLight] = useState(0);


    const getBenchmarkData = async () => {

        setBenchmarkData([]);
        setBenchmarkMetrics([]);
        setBenchmarkMetricsData([]);

        try {
            const [dataBenchmark, dataCounterBenchmark, dataBenchmarkMetrics, // dataBenchmarkMetricsData,
                // dataBenchmarkDiscardedData,
                // dataBenchmarkOnHoldData,
                dataResolvedPredictedOK, dataResolvedPredictedNOKTrained, dataResolvedPredictedNOKNotTrained, dataResolvedNotPredictedOK, dataResolvedNotPredictedNOKTrained, dataResolvedNotPredictedNOKNotTrained,

                dataOnHoldPredicted, dataOnHoldNotPredicted, dataDiscardedPredicted, dataDiscardedNotPredicted] = await Promise.all([fetchBenchmarkData(benchmarkID), fetchBenchmarkCounter(benchmarkID), fetchBenchmarkMetrics(benchmarkID), fetchBenchmarkDataResolvedPredictedOK(benchmarkID), fetchBenchmarkDataResolvedPredictedNOKTrained(benchmarkID), fetchBenchmarkDataResolvedPredictedNOKNotTrained(benchmarkID), fetchBenchmarkDataResolvedNotPredictedOK(benchmarkID), fetchBenchmarkDataResolvedNotPredictedNOKTrained(benchmarkID), fetchBenchmarkDataResolvedNotPredictedNOKNotTrained(benchmarkID), fetchBenchmarkDataOnHoldPredicted(benchmarkID), fetchBenchmarkDataOnHoldNotPredicted(benchmarkID), fetchBenchmarkDataDiscardedPredicted(benchmarkID), fetchBenchmarkDataDiscardedNotPredicted(benchmarkID),]);


            if (dataBenchmark.success) {
                setBenchmarkData(dataBenchmark.result);
                if (dataBenchmark.result.comment !== undefined) setStartComment(dataBenchmark.result.comment);
                if (dataBenchmark.result.highlightLevel !== undefined) setStartHighLight(dataBenchmark.result.highlightLevel);
            }
            if (dataCounterBenchmark.success) setCounterBenchmarkData(dataCounterBenchmark.result);
            if (dataBenchmarkMetrics.success) setBenchmarkMetrics(dataBenchmarkMetrics.result);
            // if (dataBenchmarkMetricsToResolveNotPredictedData.success) setBenchmarkMetricsToResolveNotPredicted(dataBenchmarkMetricsToResolveNotPredictedData.result);
            // if (dataBenchmarkMetricsData.success) setBenchmarkMetricsData(dataBenchmarkMetricsData.result);
            // if (dataBenchmarkDiscardedData.success) setBenchmarkDiscardedData(dataBenchmarkDiscardedData.result);
            // if (dataBenchmarkOnHoldData.success) setBenchmarkOnHoldData(dataBenchmarkOnHoldData.result);

            if (dataResolvedPredictedOK.success) setBenchmarkMetricsDataResolvedPredictedOK(dataResolvedPredictedOK.result);
            if (dataResolvedPredictedNOKTrained.success) setBenchmarkMetricsDataResolvedPredictedNKTrained(dataResolvedPredictedNOKTrained.result);
            if (dataResolvedPredictedNOKNotTrained.success) setBenchmarkMetricsDataResolvedPredictedNKNotTrained(dataResolvedPredictedNOKNotTrained.result);

            if (dataResolvedNotPredictedOK.success) setBenchmarkMetricsDataResolvedNotPredictedOK(dataResolvedNotPredictedOK.result);
            if (dataResolvedNotPredictedNOKTrained.success) setBenchmarkMetricsDataResolvedNotPredictedNKTrained(dataResolvedNotPredictedNOKTrained.result);
            if (dataResolvedNotPredictedNOKNotTrained.success) setBenchmarkMetricsDataResolvedNotPredictedNKNotTrained(dataResolvedNotPredictedNOKNotTrained.result);

            if (dataOnHoldPredicted.success) setBenchmarkMetricsDataOnHoldPredicted(dataOnHoldPredicted.result);
            if (dataOnHoldNotPredicted.success) setBenchmarkMetricsDataOnHoldNotPredicted(dataOnHoldNotPredicted.result);
            if (dataDiscardedPredicted.success) setBenchmarkMetricsDataDiscardedPredicted(dataDiscardedPredicted.result);
            if (dataDiscardedNotPredicted.success) setBenchmarkMetricsDataDiscardedNotPredicted(dataDiscardedNotPredicted.result);

            console.log("Set all data");
            return true;
        } catch (error) {
            console.error("One of the functions failed", error);
            return false;
        }
    };

    useEffect(() => {
        if (!hasRun.current) {
            // Set the ref to true so this block doesn't run again
            hasRun.current = true;

            console.log("Fetching data from server");
            getBenchmarkData().then((r) => {
                console.log("Fetched data from server r = " + r);
                setLoading(false);
            });

        }
    }, []);

    if (loading) {
        console.log("Loading...")
        return <div>Loading...</div>;
    }

    if (!benchmarkData || benchmarkData.length === 0) {
        console.log("No data available");
        return <div>No data available</div>;
    }


    const handleButtonClick = (nullData = false) => {
        navigate(`/validationBenchmark/${benchmarkID}?nullData=${nullData}`);
    };

    //create function to double click and process this crop
    //redirect to ValidationDemandPage with demand id

    return (<div className={'div-content'}>

            <div className="div-info">
                <h1>Benchmark Info</h1>

                <div>
                    <label>benchmark: <span>{benchmarkData._id}</span></label> <br/>
                </div>
                <div>
                    <label>organizationId: <span>{benchmarkData.organizationId}</span></label> <br/>
                    <label>organizationAuthId: <span>{benchmarkData.organizationAuthId}</span></label> <br/>
                    <label>machineCode: <span>{benchmarkData.machineCode}</span></label> <br/>
                    <label>machineId: <span>{benchmarkData.machineId}</span></label> <br/>

                </div>
                <div>
                    <label>status: <span>{benchmarkData.status}</span></label> <br/>
                    <label>nrShops: <span>{benchmarkData.nrShops}</span></label> <br/>
                    <label>startDate: <span>{benchmarkData.startDate}</span></label> <br/>
                    <label>endDate: <span>{benchmarkData.endDate}</span></label> <br/>


                </div>

            </div>

            <div className="div-info">

                <h1>Benchmark counter</h1>

                <div>
                    <label>benchmark: <span>{counterBenchmarkData.benchmark}</span></label> <br/>
                </div>
                <div>
                    <label>ToResolve: <span>{counterBenchmarkData.benchmarkToResolve}</span></label> <br/>
                    <label>InResolution: <span>{counterBenchmarkData.benchmarkInResolution}</span></label> <br/>
                    <label>Discarded: <span>{counterBenchmarkData.benchmarkDiscarded}</span></label> <br/>
                    <label>OnHold: <span>{counterBenchmarkData.benchmarkOnHold}</span></label> <br/>
                    <label>Resolved: <span>{counterBenchmarkData.benchmarkResolved}</span></label> <br/>

                    <label>ToResolve (predicted): <span>{counterBenchmarkData.benchmarkToResolvePredicted}</span></label> <br/>
                    <label>ToResolve (not predicted): <span>{counterBenchmarkData.benchmarkToResolveNotPredicted}</span></label> <br/>
                </div>
                <div className={'div-buttons'}>
                    <button onClick={() => handleButtonClick()}
                            disabled={counterBenchmarkData.benchmarkToResolve === 0}>Validation predicted data
                    </button>
                    <button onClick={() => handleButtonClick(true)}
                            disabled={counterBenchmarkData.benchmarkToResolve === 0}>Validation null data
                    </button>
                </div>

            </div>


            <div className="div-metrics">
                <h1>Metrics</h1>

                {!(benchmarkMetrics.length === 0) && (<div>
                        <div>
                            <label>TOTAL: <span>{benchmarkMetrics.count.Total.Total}<br/></span></label>
                            <div className={'div-label-span'}>
                                <label>Predicted :</label>
                                <span>{benchmarkMetrics.count.Total.Predicted}</span>
                                <span/>
                                <span/>
                                <span>{benchmarkMetrics.score.Total.Predicted} %</span>
                            </div>
                            <div className={'div-label-span'}>
                                <label>Not Predicted :</label>
                                <span>{benchmarkMetrics.count.Total.NotPredicted}</span>
                                <span/>
                                <span/>
                                <span>{benchmarkMetrics.score.Total.NotPredicted} %</span>
                            </div>

                        </div>

                        <div>
                            <h3>Resolved</h3>
                            <div>
                                <div className={'div-label-span'}>
                                    <label>TOTAL:</label>
                                    <span>{benchmarkMetrics.count.Resolved.Total}</span>
                                    <span/>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Resolved.Total} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Predicted:</label>
                                    <span>{benchmarkMetrics.count.Resolved.Predicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Not Predicted:</label>
                                    <span>{benchmarkMetrics.count.Resolved.NotPredicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedTotal} %</span>
                                </div>

                                <h5>Predicted</h5>

                                <div className={'div-label-span'}>
                                    <label>OK:</label>
                                    <span>{benchmarkMetrics.count.Resolved.PredictedOk}</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedOk} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedOkResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedOkTotal} %</span>
                                </div>
                                <div className={'div-label-span'} style={{color: 'red'}}>
                                    <label style={{color: 'red'}}>NOK (trained):</label>
                                    <span>{benchmarkMetrics.count.Resolved.PredictedNokTrained}</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokTrained} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokTrainedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokTrainedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>NOK (not trained):</label>
                                    <span>{benchmarkMetrics.count.Resolved.PredictedNokNotTrained}</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokNotTrained} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokNotTrainedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.PredictedNokNotTrainedTotal} %</span>
                                </div>

                                <h5>Not Predicted</h5>

                                <div className={'div-label-span'}>
                                    <label>OK:</label>
                                    <span>{benchmarkMetrics.count.Resolved.NotPredictedOk}</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedOk} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedOkResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedOkTotal} %</span>
                                </div>
                                <div className={'div-label-span'} style={{color: 'orange'}}>
                                    <label style={{color: 'orange'}}>NOK (trained):</label>
                                    <span>{benchmarkMetrics.count.Resolved.NotPredictedNokTrained}</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokTrained} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokTrainedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokTrainedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>NOK (not trained):</label>
                                    <span>{benchmarkMetrics.count.Resolved.NotPredictedNokNotTrained}</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokNotTrained} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokNotTrainedResolved} %</span>
                                    <span>{benchmarkMetrics.score.Resolved.NotPredictedNokNotTrainedTotal} %</span>
                                </div>
                            </div>

                        </div>

                        <div>
                            <h3>ToResolve</h3>
                            <div>
                                <div className={'div-label-span'}>
                                    <label>TOTAL:</label>
                                    <span>{benchmarkMetrics.count.ToResolve.Total}</span>
                                    <span/>
                                    <span/>
                                    <span>{benchmarkMetrics.score.ToResolve.Total} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Predicted:</label>
                                    <span>{benchmarkMetrics.count.ToResolve.Predicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.ToResolve.PredictedToResolve} %</span>
                                    <span>{benchmarkMetrics.score.ToResolve.PredictedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Not Predicted:</label>
                                    <span>{benchmarkMetrics.count.ToResolve.NotPredicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.ToResolve.NotPredictedToResolve} %</span>
                                    <span>{benchmarkMetrics.score.ToResolve.NotPredictedTotal} %</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3>OnHold</h3>
                            <div>
                                <div className={'div-label-span'}>
                                    <label>TOTAL:</label>
                                    <span>{benchmarkMetrics.count.OnHold.Total}</span>
                                    <span/>
                                    <span/>
                                    <span>{benchmarkMetrics.score.OnHold.Total} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Predicted:</label>
                                    <span>{benchmarkMetrics.count.OnHold.Predicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.OnHold.PredictedOnHold} %</span>
                                    <span>{benchmarkMetrics.score.OnHold.PredictedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Not Predicted:</label>
                                    <span>{benchmarkMetrics.count.OnHold.NotPredicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.OnHold.NotPredictedOnHold} %</span>
                                    <span>{benchmarkMetrics.score.OnHold.NotPredictedTotal} %</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3>Discarded</h3>
                            <div>
                                <div className={'div-label-span'}>
                                    <label>TOTAL:</label>
                                    <span>{benchmarkMetrics.count.Discarded.Total}</span>
                                    <span/>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Discarded.Total} %</span>
                                </div>
                                <div className={'div-label-span'} style={{color: 'red'}}>
                                    <label style={{color: 'red'}}>Predicted:</label>
                                    <span>{benchmarkMetrics.count.Discarded.Predicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Discarded.PredictedDiscarded} %</span>
                                    <span>{benchmarkMetrics.score.Discarded.PredictedTotal} %</span>
                                </div>
                                <div className={'div-label-span'}>
                                    <label>Not Predicted:</label>
                                    <span>{benchmarkMetrics.count.Discarded.NotPredicted}</span>
                                    <span/>
                                    <span>{benchmarkMetrics.score.Discarded.NotPredictedDiscarded} %</span>
                                    <span>{benchmarkMetrics.score.Discarded.NotPredictedTotal} %</span>
                                </div>
                            </div>
                        </div>

                    </div>)}
            </div>


            <div>
                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Resolved (Predicted) OK"}
                        images={benchmarkMetricsDataResolvedPredictedOK}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title="Resolved (Predicted) NK trained"
                        images={benchmarkMetricsDataResolvedPredictedNKTrained}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Resolved (Predicted) NK not trained"}
                        images={benchmarkMetricsDataResolvedPredictedNKNotTrained}
                    />
                </div>

            </div>

            <div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Resolved (NotPredicted) OK"}
                        images={benchmarkMetricsDataResolvedNotPredictedOK}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Resolved (NotPredicted) NK trained"}
                        images={benchmarkMetricsDataResolvedNotPredictedNKTrained}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Resolved (NotPredicted) NK not trained"}
                        images={benchmarkMetricsDataResolvedNotPredictedNKNotTrained}
                    />
                </div>

            </div>

            <div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"OnHold (Predicted)"}
                        images={benchmarkMetricsDataOnHoldPredicted}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"OnHold (NotPredicted)"}
                        images={benchmarkMetricsDataOnHoldNotPredicted}
                    />
                </div>

            </div>


            <div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Discarded (Predicted)"}
                        images={benchmarkMetricsDataDiscardedPredicted}
                    />
                </div>

                <div className={'div-result'}>
                    <ImageTableShow
                        title={"Discarded (NotPredicted)"}
                        images={benchmarkMetricsDataDiscardedNotPredicted}
                    />
                </div>

            </div>


            <CommentBox benchmarkID={benchmarkID} startComment={startComment} startHighLight={startHighLight}/>

        </div>

    );
};

export default BenchmarkDataPage;
