import {makeRequestUtils} from "./fetchUtils";

const CLASSIFIER_URL = process.env.REACT_APP_CLASSIFIER_URL;
const CLASSIFIER_URL_BENCHMARK = `${CLASSIFIER_URL}/benchmark`;

export const fetchBenchmarks = async () => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}`, 'GET');
};

export const fetchBenchmarkData = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/${benchmarkId}`, 'GET');
};


export const fetchCommentHighLite = async (benchmarkId, comment, highlightLevel) => {
    const payload = {
        'comment': comment,
        'highlightLevel': highlightLevel
    }

    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/${benchmarkId}/comment`, 'POST', {}, payload);
};


export const fetchBenchmarkCounter = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/counter/${benchmarkId}`, 'GET');
};


export const fetchBenchmarkMetricsToResolveNotPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metrics/ToResolveNotPredicted/${benchmarkId}`, 'GET');
};


export const fetchBenchmarkMetrics = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metrics/${benchmarkId}`, 'GET');
};

export const fetchBenchmarkMetricsData = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}`, 'GET', {}, null, 10000);
};


export const fetchBenchmarkDiscardedData = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/benchmarkDiscarded/${benchmarkId}`, 'GET');
};

export const fetchBenchmarkOnHoldData = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/benchmarkOnHold/${benchmarkId}`, 'GET');
};


//metricsData
export const fetchBenchmarkDataResolvedPredictedOK = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/predicted/OK`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataResolvedPredictedNOKTrained = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/predicted/NK/trained`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataResolvedPredictedNOKNotTrained = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/predicted/NK/notTrained`, 'GET', {}, null, 10000);
};


export const fetchBenchmarkDataResolvedNotPredictedOK = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/notPredicted/OK`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataResolvedNotPredictedNOKTrained = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/notPredicted/NK/trained`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataResolvedNotPredictedNOKNotTrained = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/resolved/notPredicted/NK/notTrained`, 'GET', {}, null, 10000);
};


// ToResolve
export const fetchBenchmarkDataToResolvePredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/toResolve/predicted`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataToResolveNotPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/toResolve/notPredicted`, 'GET', {}, null, 10000);
};


// OnHold
export const fetchBenchmarkDataOnHoldPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/onHold/predicted`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataOnHoldNotPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/onHold/notPredicted`, 'GET', {}, null, 10000);
};


// Discarded
export const fetchBenchmarkDataDiscardedPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/discarded/predicted`, 'GET', {}, null, 10000);
};
export const fetchBenchmarkDataDiscardedNotPredicted = async (benchmarkId) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/metricsData/${benchmarkId}/discarded/notPredicted`, 'GET', {}, null, 10000);
};


export const fetchShops = async (organizationId, organizationAuthId, machineId, machineCode, startDate, endDate) => {
    //const query = `organizationId=${organizationId}&organizationAuthId=${organizationAuthId}&machineId=${machineId}&machineCode=${machineCode}&startDate=${startDate}&endDate=${endDate}`
    const payload = {
        'organizationId': organizationId,
        'organizationAuthId': organizationAuthId,
        'machineId': machineId,
        'machineCode': machineCode,
        'startDate': startDate,
        'endDate': endDate
    }

    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/shops`, 'POST', {}, payload);
};


export const fetchCrop = async (benchmarkId, nullData) => {
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/crop/${benchmarkId}?nullData=${nullData}`, 'GET');
};

export const fetchImageResult = async (predictImageId, type, isNOK, productId) => {
    /*
    discarded
    onHold
    solved ->
    const raw = JSON.stringify({
        "productId": selectedImageProductId
    });
     */
    return await makeRequestUtils(`${CLASSIFIER_URL_BENCHMARK}/benchmarkInResolution/${predictImageId}/${type}`, 'POST', {}, {
        'isNOK': isNOK, 'productId': productId
    });
};

