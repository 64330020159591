// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-form {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
}

.comment-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.comment-inputs label {
    margin-bottom: 5px;
}

.comment-textarea {
    resize: vertical; /* Allow vertical resizing */
    min-height: 200px;
}

.div-highlight-level{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap:10px;
}

.highlight-level-select {
    width: 50px; /* Adjust width as needed */
}

.comment-buttons {
    display: flex;
    justify-content: flex-end;
}

.submit-button,
.edit-button,
.cancel-button {
    margin-left: 10px;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
}

.submit-button:disabled,
.edit-button:disabled,
.cancel-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.submit-button:hover,
.edit-button:hover,
.cancel-button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/features/CommentBox/CommentBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB,EAAE,4BAA4B;IAC9C,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,QAAQ;AACZ;;AAEA;IACI,WAAW,EAAE,2BAA2B;AAC5C;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;;;IAGI,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;;AAEA;;;IAGI,sBAAsB;IACtB,WAAW;IACX,mBAAmB;AACvB;;AAEA;;;IAGI,yBAAyB;AAC7B","sourcesContent":[".comment-form {\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    margin-top: 10px;\n    margin-bottom: 20px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 20px;\n}\n\n.comment-inputs {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n\n.comment-inputs label {\n    margin-bottom: 5px;\n}\n\n.comment-textarea {\n    resize: vertical; /* Allow vertical resizing */\n    min-height: 200px;\n}\n\n.div-highlight-level{\n    margin-top: 10px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: nowrap;\n    gap:10px;\n}\n\n.highlight-level-select {\n    width: 50px; /* Adjust width as needed */\n}\n\n.comment-buttons {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.submit-button,\n.edit-button,\n.cancel-button {\n    margin-left: 10px;\n    padding: 8px 16px;\n    cursor: pointer;\n    border: none;\n    border-radius: 4px;\n    background-color: #007bff;\n    color: #fff;\n}\n\n.submit-button:disabled,\n.edit-button:disabled,\n.cancel-button:disabled {\n    background-color: #ccc;\n    color: #666;\n    cursor: not-allowed;\n}\n\n.submit-button:hover,\n.edit-button:hover,\n.cancel-button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
