// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.DemandsInfoPage_divStartProcessSessionDivContent__CgRgl{
border: 1px solid #ccc;
    padding: 10px
}


.DemandsInfoPage_divInputCustomSelect__3t9fw{

}


.DemandsInfoPage_div_StartProcessSession__qkdeF{
    border: 1px solid #ccc;
}


.DemandsInfoPage_divInput__F667L {
    display: flex;
    align-items: center; /* Aligns input and label vertically centered */
    justify-content: space-between;
    width: 350px;
    position: relative;
    margin: 8px;
}

.DemandsInfoPage_inputField__NoZt9{
width: 182px;
}

.DemandsInfoPage_divButton__1Jotk{
margin: 10px
}


.DemandsInfoPage_divInput__F667L select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    width: 200px;
}


.DemandsInfoPage_divInput__F667L input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    width: 180px;
}



.DemandsInfoPage_divProcessedSessionsDivContent__08-oh{

}`, "",{"version":3,"sources":["webpack://./src/pages/Demand/DemandsInfo/DemandsInfoPage.module.css"],"names":[],"mappings":";AACA;AACA,sBAAsB;IAClB;AACJ;;;AAGA;;AAEA;;;AAGA;IACI,sBAAsB;AAC1B;;;AAGA;IACI,aAAa;IACb,mBAAmB,EAAE,+CAA+C;IACpE,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;AACA,YAAY;AACZ;;AAEA;AACA;AACA;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;;;AAIA;;AAEA","sourcesContent":["\n.divStartProcessSessionDivContent{\nborder: 1px solid #ccc;\n    padding: 10px\n}\n\n\n.divInputCustomSelect{\n\n}\n\n\n.div_StartProcessSession{\n    border: 1px solid #ccc;\n}\n\n\n.divInput {\n    display: flex;\n    align-items: center; /* Aligns input and label vertically centered */\n    justify-content: space-between;\n    width: 350px;\n    position: relative;\n    margin: 8px;\n}\n\n.inputField{\nwidth: 182px;\n}\n\n.divButton{\nmargin: 10px\n}\n\n\n.divInput select {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 14px;\n    color: #333;\n    width: 200px;\n}\n\n\n.divInput input {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 14px;\n    color: #333;\n    width: 180px;\n}\n\n\n\n.divProcessedSessionsDivContent{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divStartProcessSessionDivContent": `DemandsInfoPage_divStartProcessSessionDivContent__CgRgl`,
	"divInputCustomSelect": `DemandsInfoPage_divInputCustomSelect__3t9fw`,
	"div_StartProcessSession": `DemandsInfoPage_div_StartProcessSession__qkdeF`,
	"divInput": `DemandsInfoPage_divInput__F667L`,
	"inputField": `DemandsInfoPage_inputField__NoZt9`,
	"divButton": `DemandsInfoPage_divButton__1Jotk`,
	"divProcessedSessionsDivContent": `DemandsInfoPage_divProcessedSessionsDivContent__08-oh`
};
export default ___CSS_LOADER_EXPORT___;
