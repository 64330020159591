// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-switch-div {
    display: flex;
}


.toggle-switch {
    border-radius: 30px;
    transition: background-color 0.3s;
    position: absolute;
}


.toggle-switch {
    position: relative;
    display: inline-block;
    width: 68px;
    height: 40px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.toggle-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 40px;
}


.toggle-switch-slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 6px;
    bottom: 6px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .toggle-switch-slider {
    background-color: #2196F3;
}

input:checked + .toggle-switch-slider:before {
    transform: translateX(28px);
}


.toggle-switch-slider-text {
    padding-left: 4px;
    font-size: 32px;
    font-weight: bold;
    transition: opacity 0.3s;
    margin-left: 3px;
}


`, "",{"version":3,"sources":["webpack://./src/features/ToggleSwitch/ToggleSwitch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI,mBAAmB;IACnB,iCAAiC;IACjC,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;AACb;;;AAGA;IACI,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IACtB,eAAe;IACf,mBAAmB;AACvB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;;AAGA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;AACpB","sourcesContent":[".toggle-switch-div {\n    display: flex;\n}\n\n\n.toggle-switch {\n    border-radius: 30px;\n    transition: background-color 0.3s;\n    position: absolute;\n}\n\n\n.toggle-switch {\n    position: relative;\n    display: inline-block;\n    width: 68px;\n    height: 40px;\n}\n\n.toggle-switch input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n\n.toggle-switch-slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    transition: .4s;\n    border-radius: 40px;\n}\n\n\n.toggle-switch-slider:before {\n    position: absolute;\n    content: \"\";\n    height: 28px;\n    width: 28px;\n    left: 6px;\n    bottom: 6px;\n    background-color: white;\n    transition: .4s;\n    border-radius: 50%;\n}\n\ninput:checked + .toggle-switch-slider {\n    background-color: #2196F3;\n}\n\ninput:checked + .toggle-switch-slider:before {\n    transform: translateX(28px);\n}\n\n\n.toggle-switch-slider-text {\n    padding-left: 4px;\n    font-size: 32px;\n    font-weight: bold;\n    transition: opacity 0.3s;\n    margin-left: 3px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
