// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopUpImage_popup__KtMac {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures the popup is above other elements */
}


.PopUpImage_popupContent__p6sJV {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    max-height: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 800px;
    height: 400px;
}

.PopUpImage_divTopBar__FawNL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Your existing styles */
}

.PopUpImage_popupContentLeft__a-y-d {
    display: flex;
}

.PopUpImage_popupContentDivImage__qDV5n {
    padding-top: 10px;
    top: 80px
}

.PopUpImage_popupContent__p6sJV img {
    max-width: 100%;
    max-height: 80vh; /* Ensures the image fits within the viewport */
    border-radius: 10px; /* Optional: adds rounded corners to the image */
}

.PopUpImage_closeButton__tXjNM {
    background-color: #ff5c5c;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 20px;
}

.PopUpImage_closeButton__tXjNM:hover {
    background-color: #ff1c1c;
}`, "",{"version":3,"sources":["webpack://./src/features/PopUp/PopUpImage.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,8CAA8C;AACjE;;;AAGA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,wCAAwC;IACxC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB;AACJ;;AAEA;IACI,eAAe;IACf,gBAAgB,EAAE,+CAA+C;IACjE,mBAAmB,EAAE,gDAAgD;AACzE;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".popup {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000; /* Ensures the popup is above other elements */\n}\n\n\n.popupContent {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    max-width: 90%;\n    max-height: 90%;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    width: 800px;\n    height: 400px;\n}\n\n.divTopBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    /* Your existing styles */\n}\n\n.popupContentLeft {\n    display: flex;\n}\n\n.popupContentDivImage {\n    padding-top: 10px;\n    top: 80px\n}\n\n.popupContent img {\n    max-width: 100%;\n    max-height: 80vh; /* Ensures the image fits within the viewport */\n    border-radius: 10px; /* Optional: adds rounded corners to the image */\n}\n\n.closeButton {\n    background-color: #ff5c5c;\n    border: none;\n    color: white;\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 20px;\n}\n\n.closeButton:hover {\n    background-color: #ff1c1c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `PopUpImage_popup__KtMac`,
	"popupContent": `PopUpImage_popupContent__p6sJV`,
	"divTopBar": `PopUpImage_divTopBar__FawNL`,
	"popupContentLeft": `PopUpImage_popupContentLeft__a-y-d`,
	"popupContentDivImage": `PopUpImage_popupContentDivImage__qDV5n`,
	"closeButton": `PopUpImage_closeButton__tXjNM`
};
export default ___CSS_LOADER_EXPORT___;
